import React from 'react';
import { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import Scroll from '../../../components/Scroll';
import CosmoBG from '../../../assets/images/cosmo-bg.jpg';
import CourseCard from '../../../components/CourseCard';

const OhioCosmetology = () => {
  const [width, setWidth] = useState(200);
  const [test, setTest] = useState('');
  const courseMaterialsLinks = [
    'https://storage.googleapis.com/advancece/Cosmetology/Ohio/OH%20Cosmo%20Version%201.pdf',
    'https://storage.googleapis.com/advancece/Cosmetology/Ohio/OH%20Cosmo%20Version%202.pdf',
    'https://storage.googleapis.com/advancece/Cosmetology/Ohio/OH%20Cosmo%20Version%203.pdf',
  ];

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  const links = [{ href: '/OH/Cosmetology/FAQ', label: 'FAQs' }];

  const _renderActionButtons = () => {
    const courseMaterialsLink = courseMaterialsLinks[Math.floor(Math.random() * courseMaterialsLinks.length)];
    return (
      <ul className="actions special" style={{ marginTop: 30 }}>
        <li>
          <Scroll type="id" element="materials">
            <a href={'#'} target="_blank" className="button primary" style={{ backgroundColor: '#ff6400' }}>
              View Course Materials
            </a>
          </Scroll>
        </li>
        <li>
          <a className="button secondary" href={`/OH/Cosmetology/OHCOS3`}>
            Take Test
          </a>
        </li>
      </ul>
    );
  };

  const helmetMeta = [
    {
      name: 'description',
      content:
        'Advance Continuing Education offers cosmetology license renewal courses for Ohio cosmetologists, facial specialists, nail specialists, and full specialists.',
    },
    {
      name: 'keywords',
      content:
        'Ohio Cosmetology License Renewal, nail license, continuing education for cosmetologists, esthetician ce, fl cosmetology license renewal, ceu classes, facial specialist ce, license renewal course, hiv aids course',
    },
  ];

  return (
    <Layout links={links} logoUrl="/OH/Cosmetology" helmetMeta={helmetMeta} helmetTitle="OH Cosmetology License Renewal">
      <section
        id="banner"
        style={{
          backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.6), rgba(0,0,0,0.6)),url(${CosmoBG})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="inner">
          <h2>cosmetology license renewal</h2>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '100%', maxWidth: 900 }}>
              <h3
                style={{
                  color: '#ff6400',
                  marginBottom: 15,
                  textDecoration: 'underline',
                }}
              >
                Satisfies Mandatory Requirements For
              </h3>
              <p style={{ color: '#019cff', fontSize: '1.0em' }}>PROFESSIONALS LICENSED BY THE OHIO COSMETOLOGY BOARD</p>
              <h4 style={{ color: '#019cff', marginBottom: 10 }}>
                ENTIRE PROGRAM <s style={{ color: 'red' }}>$29</s> $15
              </h4>
              {_renderActionButtons()}
            </div>
          </div>
        </div>
        <Scroll type="id" element="getting-started">
          <a href="#getting-started" className="more">
            Learn More
          </a>
        </Scroll>
      </section>
      <section
        id="getting-started"
        className="wrapper style2 special"
        style={{
          backgroundColor: '#019cff',
          minHeight: 400,
          maxHeight: 900,
        }}
      >
        <div className="inner">
          <h2 style={{ color: 'white', fontSize: 20 }}>Renew Your Ohio Cosmetology License with Advance!</h2>
          <p style={{ textAlign: 'left', color: 'white' }}>
            Don't let your cosmetology license expire! Here at Advance CE, we offer everything you need to seamlessly renew your license,
            sharpen your knowledge, and Advance your career. We provide license renewal courses that cater to every facet of cosmetology
            including Cosmetologists, Estheticians and Manicurists. We have curated content designed to fulfill your OH cosmetology license
            renewal requirements with ease.
          </p>
          <h2 style={{ color: 'white', marginBottom: 15, fontSize: 20 }}>GETTING STARTED</h2>
          <ol style={{ textAlign: 'left' }}>
            <li>
              <span style={{ color: 'white', fontWeight: 'bold' }}>Select your specialty</span> from the dropdown menu above
            </li>
            <li>
              <span style={{ color: 'white', fontWeight: 'bold' }}>Select “Take Test”</span> option on the home page or in the sidebar
            </li>
            <li>
              <span style={{ color: 'white', fontWeight: 'bold' }}>Fill in the answers</span> for all courses you’d like to receive credit
              for, hit “Submit”
            </li>
            <li>
              <span style={{ color: 'white', fontWeight: 'bold' }}>Create an account</span> or sign in
            </li>
            <li>
              <span style={{ color: 'white', fontWeight: 'bold' }}>Input</span> your license and payment information, and then complete the
              evaluation
            </li>
            <li>
              <span style={{ color: 'white', fontWeight: 'bold' }}>Receive your certificate</span> instantly via email
            </li>
          </ol>
        </div>
      </section>
      <section id="materials" className="wrapper style2 special" style={{ minHeight: 600 }}>
        <div
          className="inner"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <header className="major">
            <h2>Course Materials</h2>
          </header>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around' }}>
            <CourseCard title={`VERSION 1: OHIO SAFETY AND INFECTION CONTROL COURSE`} price={``} href={courseMaterialsLinks[0]} />
            <CourseCard title={`VERSION 2: OHIO SAFETY AND INFECTION CONTROL COURSE`} price={``} href={courseMaterialsLinks[1]} />
            <CourseCard title={`VERSION 3: OHIO SAFETY AND INFECTION CONTROL COURSE`} price={``} href={courseMaterialsLinks[2]} />
          </div>
        </div>
      </section>

      {/* <section id="three" className="wrapper style3 special">
        <div className="inner">
          <header className="major">
            <h2>Engineering Tests</h2>
            <p>
              Advance offers the following tests for engineers in the great
              state of Florida
              <br />
              Have a book code already? Enter it here to begin taking the test
            </p>
          </header>
          <ul className="features">
            <li className="icon fa-paper-plane">
              <h3>Enter Book Code</h3>
              <p>Enter your book code to be taken to the test.</p>
              <input
                className="primary"
                type="text"
                placeholder="FL0013"
                onChange={(e) => setTest(e.target.value)}
              />

              <a
                className="button secondary"
                style={{ width: '100%', marginTop: 10 }}
                onClick={() =>
                  (window.location.href = `/FL/Engineering/${test}`)
                }
              >
                Take Test
              </a>
            </li>
            <li className="icon solid fa-laptop">
              <h3>Test Quiz 1</h3>
              <a
                className="button secondary"
                style={{ width: '100%', marginTop: 10 }}
                onClick={() =>
                  (window.location.href = `/FL/Engineering/FLENG51`)
                }
              >
                Take Test
              </a>
            </li>
          </ul>
        </div>
      </section> */}

      <section id="cta" className="wrapper style4">
        <div className="inner">
          <header>
            <h3>Have a Question? Contact Us!</h3>
            <p>A representative will respond to you as soon as possible.</p>
          </header>
          <ul className="actions stacked">
            <li>
              <a href="/ContactUs" className="button fit primary">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </section>
    </Layout>
  );
};

export const Head = () => {
  return (
    <>
      <meta property="og:title" content="Renew Cosmetology License - 10 Minutes!" />
      <meta property="og:description" content="Ohio Cosmetology Licence Renewal" />
      <meta property="og:image" content="https://storage.googleapis.com/advancece/advancece-cos.png" />
    </>
  );
};

export default OhioCosmetology;
